import { Icon } from '@datasnipper/icons'
import { Badge } from '@datasnipper/ui/badge'
import { Card, CardContent, CardTitle } from '@datasnipper/ui/card'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'
import isEmpty from 'lodash/isEmpty'

import { textSizeClasses } from '#app/constants/shared'
import {
  BadgeAttributes,
  ImageAttributes,
  LinkAttributes,
  TextSizeClass,
} from '#types/shared'

const backgroundColors = {
  action: 'bg-action-25',
  core: 'bg-core-25',
  highlight: 'bg-highlight-25',
  neutral: 'bg-neutral-25',
}

export type FeatureCardProps = {
  title: string
  titleSize?: TextSizeClass
  description: string
  image: ImageAttributes
  badge: BadgeAttributes
  link: LinkAttributes
  reverseOrder?: boolean
  textAlignment?: 'left' | 'center'
  backgroundColor?: keyof typeof backgroundColors
}

export type ProductAnnouncementCardProps = {
  badge?: BadgeAttributes
  title?: string
  subtitle?: string
  cards: FeatureCardProps[]
}

export const ProductAnnouncementCard = ({
  title,
  subtitle,
  cards,
  badge,
}: ProductAnnouncementCardProps) => (
  <article className="flex justify-center text-center">
    <div className="container">
      {(title || subtitle) && (
        <hgroup className="mb-12">
          {badge?.text ? (
            <Badge variant={badge.variant}>
              <span className="p-1">{badge.text}</span>
            </Badge>
          ) : null}
          {title && <h2 className="mb-2 font-medium">{title}</h2>}
          {subtitle && <p className="text-xl text-body">{subtitle}</p>}
        </hgroup>
      )}
      <section className="flex flex-col gap-40 py-0">
        {cards.length <= 1 && !isEmpty(cards[0]) ? (
          <LargeFeatureCard {...cards[0]} />
        ) : (
          <div
            className={cn(
              'grid grid-cols-1 gap-8',
              cards.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-3',
            )}
          >
            {cards.map((card, index) => (
              <FeatureCard key={index} {...card} />
            ))}
          </div>
        )}
      </section>
    </div>
  </article>
)

const FeatureCard = ({
  badge,
  title,
  titleSize,
  description,
  link,
  image,
  textAlignment = 'center',
}: FeatureCardProps) => (
  <div
    className={cn('flex flex-col items-start gap-4 p-4 text-left', {
      'text-center items-center': textAlignment === 'center',
    })}
  >
    <img
      src={image.src}
      alt={image.alt}
      width={300}
      height={200}
      className="h-auto w-full rounded-lg shadow-md"
    />
    <div className="space-y-2">
      {badge.text && <Badge variant={badge.variant}>{badge.text}</Badge>}
      <h5
        className={cn({
          [textSizeClasses[titleSize || 'smaller']]: titleSize,
        })}
      >
        {title}
      </h5>
    </div>
    <p>{description}</p>
    {link.title && (
      <Link
        to={link.href}
        className="inline-flex items-center font-medium text-action-600 hover:underline"
      >
        {link.title}
        <Icon name="arrow-right" className="ml-1 size-4" />
      </Link>
    )}
  </div>
)

const LargeFeatureCard = ({
  badge,
  title,
  description,
  link,
  image,
  reverseOrder,
  textAlignment,
  backgroundColor = 'highlight',
}: FeatureCardProps) => (
  <Card className="overflow-hidden">
    <CardContent className="flex flex-col items-center gap-6 p-0 md:flex-row md:gap-16">
      <div
        className={cn(
          'w-full max-w-96 flex-1 space-y-4 p-8 text-left md:w-5/12 md:pl-16',
          {
            'pl-0 p-4 md:p-0 md:pr-16 w-full md:w-7/12 order-2': reverseOrder,
            'text-center': textAlignment === 'center',
          },
        )}
      >
        <div className="space-y-2">
          {badge.text && <Badge variant={badge.variant}>{badge.text}</Badge>}
          <CardTitle className="text-2xl md:text-3xl">{title}</CardTitle>
        </div>
        <p>{description}</p>
        {link.title && (
          <Link
            to={link.href}
            className="inline-flex items-center font-medium text-action-600 hover:underline"
          >
            {link.title}
            <Icon name="arrow-right" className="ml-1 size-4" />
          </Link>
        )}
      </div>
      <div
        className={cn(
          'flex w-full flex-1 items-center justify-center p-8 sm:p-16 md:w-7/12 xl:p-36',
          backgroundColors[backgroundColor],
          {
            'order-1 w-full md:w-5/12': reverseOrder,
          },
        )}
      >
        <img
          alt={image.alt}
          src={image.src}
          className="h-fit min-w-full rounded-md shadow-lg"
        />
      </div>
    </CardContent>
  </Card>
)
