import { Avatar } from '@datasnipper/ui/avatar'
import { Card, CardContent } from '@datasnipper/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@datasnipper/ui/tabs'
import { cn } from '@datasnipper/utils/cn'

import { TitleSection } from '../title-section/title-section'

import { backgroundClasses } from '#app/constants/shared'
import frame from '#assets/images/testimonial-frame.png'
import { BackgroundClass, ImageAttributes } from '#types/shared'

interface Testimonial {
  name: string
  quote: string
  author: string
  role: string
  logo: ImageAttributes
  avatar?: ImageAttributes
}

export type TestimonialTabsProps = {
  title?: string
  subtitle?: string
  background?: BackgroundClass
  testimonials: Testimonial[]
}

export const TestimonialTabs = ({
  title,
  subtitle,
  background = 'white',
  testimonials = [],
}: TestimonialTabsProps) => (
  <article
    className={cn(
      'flex items-center justify-center',
      backgroundClasses[background],
    )}
  >
    <Tabs
      className="container flex flex-col items-center justify-center bg-inherit"
      defaultValue={`${testimonials[0]?.name}`}
    >
      {title ? (
        <TitleSection
          background={background}
          title={title}
          subtitle={subtitle}
        />
      ) : null}
      <div
        className="flex w-full items-center justify-center rounded-lg p-8 lg:p-16"
        style={{
          backgroundImage: `url('${frame}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card className="mb-8 max-w-screen-md shadow-lg">
          {testimonials.map(testimonial => (
            <TabsContent key={testimonial.name} value={testimonial.name}>
              <CardContent className="p-8">
                <blockquote className="mb-8 text-sm italic text-neutral-600 sm:text-lg">
                  &quot;{testimonial.quote}&quot;
                </blockquote>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div>
                      {testimonial.avatar?.src && (
                        <Avatar
                          src={testimonial.avatar.src}
                          alt={`${testimonial.author} avatar`}
                          className="size-12"
                        />
                      )}
                      <div className="font-semibold">{testimonial.author}</div>
                      <div className="text-sm text-neutral-600">
                        {testimonial.role}
                      </div>
                    </div>
                  </div>
                  <img
                    src={testimonial.logo.src}
                    alt={`${testimonial.name} logo`}
                    width={100}
                    height={40}
                    className="h-10 w-auto object-contain"
                  />
                </div>
              </CardContent>
            </TabsContent>
          ))}
        </Card>
      </div>

      <TabsList className="grid w-full grid-cols-2 gap-4 bg-transparent md:grid-cols-4">
        {testimonials.map(testimonial => (
          <TabsTrigger
            key={testimonial.name}
            value={testimonial.name}
            className={cn(
              'data-[state=active]:border-b-2',
              'rounded-none border-highlight p-8 transition-none',
            )}
          >
            <img
              src={testimonial.logo.src}
              alt={`${testimonial.name} tab switch trigger`}
              width={120}
              height={40}
              className="h-10 w-auto border-b object-contain opacity-70 brightness-200"
            />
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  </article>
)
