import { IconName } from '@datasnipper/icons'

import {
  FeatureSection,
  FeatureSectionProps,
} from '#app/components/feature-section/feature-section'
import { FeatureSection as FeatureSectionEntry } from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'
import { BackgroundClass, TextSizeClass } from '#types/shared'

const mapFeatureSectionToProps = (
  entry: FeatureSectionEntry,
): FeatureSectionProps => ({
  title: entry.title ?? '',
  subtitle: entry.subtitle ?? '',
  link: {
    title: entry.link?.title ?? '',
    href: entry.link?.href ?? '',
  },
  background: getValidatedProperty(
    entry.background,
    'white',
    isValidProperty<BackgroundClass>,
  ),
  features:
    entry.feature_card_props?.map(feature => ({
      title: feature?.title ?? '',
      description: feature?.description ?? '',
      link: {
        title: feature?.link?.title ?? '',
        href: feature?.link?.href ?? '',
      },
      icon: (feature?.icon as IconName) ?? '',
      titleSize: getValidatedProperty(
        feature?.title_size,
        'smaller',
        isValidProperty<TextSizeClass>,
      ),
    })) ?? [],
})

export const CsFeatureSection = ({ entry }: { entry: FeatureSectionEntry }) => (
  <FeatureSection {...mapFeatureSectionToProps(entry)} />
)
