import { Badge } from '@datasnipper/ui/badge'
import { Button } from '@datasnipper/ui/button'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../../tailwind.config'

import { useMediaQuery } from '#app/hooks/useMediaQuery/useMediaQuery'
import leftImage from '#assets/images/header-background-left.svg'
import rightImage from '#assets/images/header-background-right.webp'
import { BadgeAttributes, ImageAttributes, LinkAttributes } from '#types/shared'

export type HeroHeaderProps = {
  badge?: BadgeAttributes
  title?: string
  subtitle?: string
  primaryLink?: LinkAttributes
  secondaryLink?: LinkAttributes
  showBackgroundImage?: boolean
  bannerImage?: ImageAttributes
  contentPlacement?: 'left' | 'center'
}

export const HeroHeader = ({
  badge,
  title,
  subtitle,
  primaryLink,
  secondaryLink,
  contentPlacement = 'left',
  showBackgroundImage = true,
  bannerImage,
}: HeroHeaderProps) => {
  const { md, lg } = resolveConfig(tailwindConfig).theme.screens

  const mediumWidthReached = useMediaQuery(`(min-width: ${md})`)

  const largeWidthReached = useMediaQuery(`(min-width: ${lg})`)

  const getContainerBgStyles = () => ({
    backgroundImage:
      mediumWidthReached && showBackgroundImage
        ? `url('${leftImage}'), url('${rightImage}')`
        : 'none',
    backgroundPosition: `0 -7rem, 100% ${largeWidthReached ? '100%' : '3rem'}`,
    backgroundRepeat: 'no-repeat, no-repeat',
    backgroundSize: 'auto, cover',
    minHeight: mediumWidthReached ? '650px' : '450px',
  })

  return (
    <article
      style={getContainerBgStyles()}
      className={cn(
        `top-0 z-50 bg-neutral-800 bg-cover bg-no-repeat`,
        `flex flex-col items-center justify-between pb-0 pt-32 md:pt-56`,
      )}
    >
      <div className="container">
        <header
          className={cn('flex items-center justify-center text-center', {
            'flex-col': !mediumWidthReached,
            'items-start justify-start text-left order-2':
              contentPlacement === 'left' && mediumWidthReached,
          })}
        >
          <section
            className={cn(
              'flex flex-col items-center justify-center gap-5 px-4 py-0 text-center',
              {
                'items-start text-left order-2':
                  contentPlacement === 'left' && mediumWidthReached,
              },
            )}
          >
            {badge?.text ? (
              <div className="flex justify-center">
                <Badge variant={badge.variant} className="px-3 py-2 text-xs">
                  {badge.text}
                </Badge>
              </div>
            ) : null}
            <h1 className="max-w-[30rem] text-pretty text-primary-foreground md:mt-0">
              {title}
            </h1>
            <p className="mb-6 max-w-2xl text-pretty text-lg text-neutral-300 md:mb-0">
              {subtitle}
            </p>

            <div className="flex max-w-96 flex-col items-center justify-center gap-5 sm:flex-row lg:justify-start">
              {primaryLink?.title ? (
                <Link to={primaryLink.href}>
                  <Button className="w-full">{primaryLink.title}</Button>
                </Link>
              ) : null}
              {secondaryLink?.title ? (
                <Link to={secondaryLink.href}>
                  <Button
                    variant="outline"
                    className="w-full border border-primary-foreground text-primary-foreground"
                  >
                    {secondaryLink.title}
                  </Button>
                </Link>
              ) : null}
            </div>
          </section>
          {bannerImage?.src && contentPlacement !== 'center' ? (
            <section
              className={cn(
                'py-0',
                contentPlacement === 'left' ? 'order-2' : 'order-1',
              )}
            >
              <img
                src={bannerImage.src}
                alt={bannerImage?.alt}
                className="mx-auto w-9/12 max-w-full"
              />
            </section>
          ) : null}
        </header>
      </div>
    </article>
  )
}
