import { mapAsset } from './mappers'

import {
  CustomerStoryCard,
  CustomerStoryCardProps,
} from '#app/components/customer-story-card/customer-story-card'
import {
  CustomerStoryCard as CustomerStoryCardEntry,
  Maybe,
} from '#app/graphql/@generated/api'

const mapCustomerStoryCardEntryToProps = (
  entry: Maybe<CustomerStoryCardEntry>,
): CustomerStoryCardProps => ({
  title: entry?.title || '',
  logos: (entry?.logosConnection?.edges ?? []).map(mapAsset),
  image: mapAsset(entry?.imageConnection?.edges?.[0]),
})

export const CsCustomerStoryCard = ({
  entry,
}: {
  entry: CustomerStoryCardEntry
}) => <CustomerStoryCard {...mapCustomerStoryCardEntryToProps(entry)} />
