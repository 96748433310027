import { Icon, IconName } from '@datasnipper/icons'
import { Button } from '@datasnipper/ui/button'
import { cn } from '@datasnipper/utils/cn'
import { Link } from '@remix-run/react'

import { LinkButton } from '../link-button/link-button'

import { backgroundClasses, textSizeClasses } from '#app/constants/shared'
import { BackgroundClass, TextSizeClass, LinkAttributes } from '#types/shared'

export interface FeatureCard {
  title: string
  description: string
  icon?: IconName
  link?: LinkAttributes
  titleSize?: TextSizeClass
}

export type FeatureSectionProps = {
  title: string
  subtitle: string
  features: FeatureCard[]
  background?: BackgroundClass
  link?: LinkAttributes
}

const FeatureCard = ({
  icon,
  title,
  description,
  link,
  titleSize,
}: FeatureCard) => (
  <div className="flex flex-col items-start text-display-xl">
    {icon ? (
      <Icon className="mb-4 size-8 self-start text-highlight" name={icon} />
    ) : null}
    <h5
      className={cn('mb-2 font-medium', {
        [textSizeClasses[titleSize || 'smaller']]: titleSize,
      })}
    >
      {title}
    </h5>

    <p className="text-lg text-body">{description}</p>
    {link?.href ? (
      <LinkButton
        openInNewTab
        showIcon
        link={{ href: link.href, children: link.title }}
      />
    ) : null}
  </div>
)

export const FeatureSection = ({
  title,
  subtitle,
  link,
  features,
  background = 'white',
}: FeatureSectionProps) => {
  const hasCallToAction = link?.href && link.title
  return (
    <article
      className={cn('flex justify-center', backgroundClasses[background])}
    >
      <div className="container">
        <section
          className={cn(
            'mb-12 flex flex-col items-start justify-between gap-5 py-0 md:flex-row md:items-center md:gap-0',
            {
              'items-center justify-center': !hasCallToAction,
            },
          )}
        >
          <hgroup>
            <h2 className="mb-2 font-medium">{title}</h2>
            <p className="text-xl text-body">{subtitle}</p>
          </hgroup>
          {hasCallToAction ? (
            <Link to={link.href}>
              <Button variant="outline">{link.title}</Button>
            </Link>
          ) : null}
        </section>
        <section className="grid grid-cols-1 gap-8 py-0 md:grid-cols-3">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </section>
      </div>
    </article>
  )
}
