import { Icon, IconName } from '@datasnipper/icons'
import { Card, CardContent, CardHeader } from '@datasnipper/ui/card'
import { cn } from '@datasnipper/utils/cn'

import { Title } from '../title-section/title-section'

import { backgroundClasses } from '#app/constants/shared'
import { BackgroundClass, ImageAttributes } from '#types/shared'

export interface Feature {
  icon: IconName
  title: string
  description: string
}

export type CollaborationFeaturesProps = {
  title: string
  image: ImageAttributes
  features: Feature[]
  background?: BackgroundClass
  subtitle?: string
}

const FeatureCard = ({ icon, title, description }: Feature) => (
  <Card className="mx-auto w-full min-w-full max-w-xs transition-all hover:-translate-y-1 hover:shadow-lg md:min-w-48 xl:min-w-96">
    <CardHeader>
      <div className="flex items-center gap-3">
        <Icon className="size-6 text-highlight-500" name={icon} />
        <h3 className="font-sans text-lg font-semibold">{title}</h3>
      </div>
    </CardHeader>
    <CardContent className="text-neutral-500">{description}</CardContent>
  </Card>
)

export const CollaborationFeatures = ({
  title,
  subtitle,
  image,
  features,
  background = 'white',
}: CollaborationFeaturesProps) => (
  <article className={cn('flex justify-center', backgroundClasses[background])}>
    <div>
      {title && <Title title={title} subtitle={subtitle} />}
      <div className="relative my-12 px-4 md:px-8">
        <img
          src={image.src}
          alt={image.alt || title}
          width={1280}
          height={544}
          className="h-[544px] w-full rounded-lg object-cover"
        />
      </div>

      <div className="relative z-10 -mt-24 grid gap-3 px-4 md:grid-cols-3">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  </article>
)
