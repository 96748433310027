import { Card, CardContent, CardHeader } from '@datasnipper/ui/card'
import { Link } from '@remix-run/react'

import { ImageAttributes } from '#types/shared.js'

type Props = {
  image: ImageAttributes
  text: string
  slug: string
}

export function LinkPreviewCard({ image, text, slug }: Props) {
  return (
    <Link reloadDocument to={slug} target="_blank" rel="noreferrer noopener">
      <Card className="max-w-72">
        <CardHeader className="p-0">
          <img className="rounded-lg" alt={image.alt} src={image.src} />
        </CardHeader>
        <CardContent className="p-5">
          <p className="text-base">{text}</p>
        </CardContent>
      </Card>
    </Link>
  )
}
