import { IconName } from '@datasnipper/icons'
import { Maybe } from 'graphql/jsutils/Maybe'

import { mapAsset } from './mappers'

import {
  CollaborationFeatures,
  CollaborationFeaturesProps,
} from '#app/components/collaboration-features/collaboration-features'
import { CollaborationFeatures as CollaborationFeaturesEntry } from '#app/graphql/@generated/api'

const mapEntryToProps = (
  entry: Maybe<CollaborationFeaturesEntry>,
): CollaborationFeaturesProps => ({
  title: entry?.title ?? '',
  subtitle: entry?.subtitle ?? '',
  image: mapAsset(entry?.imageConnection?.edges?.[0]),
  features:
    entry?.features?.map(feature => ({
      icon: (feature?.icon as IconName) ?? '',
      title: feature?.title ?? '',
      description: feature?.description ?? '',
    })) ?? [],
})

export const CSCollaborationFeatures = ({
  entry,
}: {
  entry: CollaborationFeaturesEntry
}) => <CollaborationFeatures {...mapEntryToProps(entry)} />

export default CSCollaborationFeatures
