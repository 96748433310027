import { ImageAttributes } from '#types/shared'

export interface CustomerStoryCardProps {
  title: string
  logos: ImageAttributes[]
  image: ImageAttributes
}

export const CustomerStoryCard = ({
  title,
  logos,
  image,
}: CustomerStoryCardProps) => (
  <article className="flex flex-col items-center justify-center">
    <div className="mx-8 flex max-w-screen-container flex-col justify-between rounded-md bg-neutral-50 lg:mx-16 lg:flex-row">
      <div className="flex flex-col justify-center p-8 lg:max-w-md">
        <h2 className="mb-12 font-normal">{title}</h2>
        <div className="grid max-w-md grid-flow-row-dense grid-cols-2 gap-8">
          {logos.map(logo => (
            <img
              className="h-8 lg:h-10"
              key={logo.src}
              src={logo.src}
              alt={logo.alt}
            />
          ))}
        </div>
      </div>
      <img
        src={image.src}
        alt={image.alt || title}
        className="max-w-full rounded-md lg:max-w-xl"
      />
    </div>
  </article>
)
