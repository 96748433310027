import { mapAsset } from './mappers'

import {
  ProductAnnouncementCard,
  ProductAnnouncementCardProps,
  FeatureCardProps,
} from '#app/components/product-announcement-card/product-announcement-card'
import { ProductAnnouncementCard as ProductAnnouncementCardEntry } from '#app/graphql/@generated/api'
import { getValidatedProperty, isValidProperty } from '#app/utils/shared'
import { BadgeAttributes, LinkAttributes, TextSizeClass } from '#types/shared'

const mapEntryToProps = (
  entry: ProductAnnouncementCardEntry,
): ProductAnnouncementCardProps => ({
  title: entry?.title ?? '',
  subtitle: entry?.subtitle ?? '',
  badge: {
    text: entry?.badge?.text || '',
    variant: getValidatedProperty(
      entry?.badge?.variant,
      'primary',
      isValidProperty<BadgeAttributes['variant']>,
    ),
  },
  cards: (entry?.cards ?? []).map(card => ({
    title: card?.title ?? '',
    titleSize: getValidatedProperty(
      card?.title_size,
      'smaller',
      isValidProperty<TextSizeClass>,
    ),
    description: card?.description ?? '',
    link: card?.link as LinkAttributes,
    reverseOrder: card?.reverse_order ?? false,
    image: mapAsset(card?.imageConnection?.edges?.[0]) ?? '',
    badge: {
      text: card?.badge?.text || '',
      variant: getValidatedProperty(
        card?.badge?.variant,
        'primary',
        isValidProperty<BadgeAttributes['variant']>,
      ),
    },
    backgroundColor: getValidatedProperty(
      card?.background_colors,
      'neutral',
      isValidProperty<FeatureCardProps['backgroundColor']>,
    ),
    textAlignment: getValidatedProperty(
      card?.text_alignment,
      'left',
      isValidProperty<FeatureCardProps['textAlignment']>,
    ),
  })),
})

export const CsProductAnnouncementCard = ({
  entry,
}: {
  entry: ProductAnnouncementCardEntry
}) => <ProductAnnouncementCard {...mapEntryToProps(entry)} />
